<template>
<div>
  <div class="card" style="max-height: 100px" >
    <div class="card-header">
    <b-row>
      <b-col
         md="2"
         xl="2"
      >
        <b-form-group
          label="Nº OP:"
        >
          <b-form-input
            id="IDIPROC"
            placeholder=""
            v-model="ApontamentoProducao.idiproc"
            readonly
            autocomplete="off"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="6"
      >
        <b-form-group
          label="Produto:"
        >
          <b-form-input
            placeholder=""
            v-model="ApontamentoProducao.descrprod"
            required
            readonly
            autocomplete="off"
          />
        </b-form-group>
      </b-col>

    
      <b-col
         md="2"
         xl="2"
      >
        <b-form-group
          label="Ciclo:"
        >
          <b-form-input
            id="Etapa"
            placeholder=""
            v-model="this.CICLO.AD_CICLO"
            readonly
            autocomplete="off"
          />
        </b-form-group>
      </b-col>


    </b-row>
  </div>
  </div>

  <div class="card" style="max-height: 100px" v-if="this.BloquearEdicao == false"> 
    <div class="card-body">
      
      <b-row>
          <b-col md="3" xl="3" class="mb-1">
          <b-form-group label="Opção:" >
            <multiselect   
                v-model="NUCAMPO"
                placeholder="Selecione"
                label="DESCRCAMPO"
                track-by="NUCAMPO"
                :options="TipoFormOption"
                :multiple="false"
                :taggable="false"
                :close-on-select="true"
                :option-height="50"
                :show-labels="false"
              >
            </multiselect>

          </b-form-group>
         </b-col>


         <b-col md="2" xl="2" class="mb-1">
         <b-form-group label="Quantidade" >
             <b-form-input 
                    v-model="VALOR"
                    id="VALOR"
                    class="font-size11"
                    type="number"
                    autofocus
                    autocomplete="off"
                  />
          </b-form-group>
        </b-col>

         <b-col md="2" xl="2" class="mb-1">
          <b-form-group label="" >
            <br>
               <div class="DivBotaoLadoLado">
                <b-button v-if="PermiteAddForm"
                  variant="success"
                  @click="AddForm"
                >
                <feather-icon
                 :icon="'PlusCircleIcon'"
                 size="15"
                 /> 
                  <span>Incluir</span>
                </b-button>
               </div>
         </b-form-group>
         </b-col>
         

      </b-row>   
     
    </div>
  </div>
  
  <div class="card">
    
    <b-row>
      <b-col
        md="12"
        xl="12"
      >
    <b-table
      striped       
      responsive
      sticky-header
      :items="this.ApontamentoProducao.formulario"
      :fields="fields"
      table-class = "font-size11" 
      style="max-height: 315px;"
    >

    <template #cell(NUCAMPO)="row">
    <div>
      <div class="DivBotaoLadoLado col-lg-4 col-md-4 col-xl-4" >
          <b-form-group label="Tipo" >
            <b-form-input 
                    id="DESCRCAMPO"
                    v-model="row.item.DESCRCAMPO"
                    class="font-size11"
                    readonly
                    autocomplete="off"
                  />
          
          </b-form-group>
          
        
       </div>

       <div class="DivBotaoLadoLado col-lg-2 col-md-2 col-xl-2">
          <b-form-group label="Quantidade" >
             <b-form-input 
                    id="VALOR"
                    v-model="row.item.VALOR"
                    class="font-size11"
                    type="number"
                    autofocus
                    autocomplete="off"
                    :readonly="row.item.SITUACAO !== 'C' && BloquearEdicao == false ? false : ''"
                    @input="EditarForm(row.item)"
                  />
          </b-form-group>
       </div>

      


      <div class="DivBotaoLadoLado col-1" v-if="row.item.SITUACAO !== 'C' && BloquearEdicao == false" >  
         <b-form-group label="" >
          
          
          <div class="DivBotaoLadoLado" v-if="CicloEditando ==''"  >  
          <b-button 
             variant="danger"
             size="sm" @click="RemoveForm(row.item)" class="mr-1">
             <feather-icon
                 :icon="'Trash2Icon'"
                 size="15"
              />
          </b-button>
          </div>


          <div class="DivBotaoLadoLado" v-if="row.item.NUCAMPO == CicloEditando" >  
          <b-button 
             variant="warning"
             size="sm" @click="CancelarEdicao(row.item)" class="mr-1">
             <feather-icon
                 :icon="'ArrowLeftCircleIcon'"
                 size="15"
              />
          </b-button>
          </div>

          <div class="DivBotaoLadoLado"  v-if="row.item.NUCAMPO == CicloEditando" >  
          <b-button 
             variant="success"
             size="sm" @click="AtualizarForm(row.item)" class="mr-1">
             <feather-icon
                 :icon="'CheckIcon'"
                 size="15"
              />
          </b-button>
          </div>
         </b-form-group>                   
      </div>
      
     
    </div>
      </template>
 

    </b-table>
      
      </b-col>
    </b-row>

   <b-row>
      <b-col
        md="12"
        xl="12"
        class="mb-1"
      >

      <div class="DivBotaoLadoLado" >
          <b-button
           variant="danger"
           type="button"
           @click="Retornar()"
         >
           <span>RETORNAR</span>
         </b-button>
      </div>




    </b-col>

    </b-row>
  
  </div>


  

</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import BCardCode from '@core/components/b-card-code'
import {
  BFormInput, BRow, BCol, BFormGroup, BButton, BFormDatepicker,BTable, BFormSelect,
} from 'bootstrap-vue'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import Spinner from '@/components/Spinner.vue'

export default {
  components: {
    BCardCode,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BTable,
    BFormSelect,
    Multiselect,
  },
  data() {
    return {
      ApontamentoProducao: {
        idiproc: "",
        versao: "",
        codprc: "",
        codprod: "",
        descrprod: "",
        qtdproduzir: "",
        descricaoetapa: "",
        descstatus:"",
        ciclos:[],
        formulario:[]
      },
      TipoFormOptionBase: [],
      TipoFormOption: [],
      NUCAMPO: [],
      VALOR: '',
      CICLO:'',
      fields: [
        { key: 'NUCAMPO', label: ''  }
      ],
      BloquearEdicao: false,
      PermiteAddForm: false,
      CicloEditando: ''
    }
  },
  methods: {
    async AddForm() {
      
      let MsgValidacao = ''
      let NUCAMPO = ''
      let VALOR = this.VALOR

      if(this.NUCAMPO != null){
        if(Array.isArray(this.NUCAMPO) == false)
        {
          NUCAMPO = this.NUCAMPO.NUCAMPO
        }
      }

      if(NUCAMPO == ''){
        MsgValidacao = 'Selecione uma Opção'
      }

      if(VALOR == ''){
        MsgValidacao = 'Informe alguma Quantidade'
      }

      if(MsgValidacao == ''){
        this.AtualizarFormCampo(NUCAMPO,VALOR)
        this.VALOR = ''
        this.NUCAMPO = []

      }

      let notification = { message: MsgValidacao, alert_type: 'attention' }
      AlertaMsg.FormataMsg(notification)

    },
    async RemoveForm(Ciclo) {
      
      let NUCAMPO = Ciclo.NUCAMPO
      this.AtualizarFormCampo(NUCAMPO,"")
      
      
    },
    async AtualizarFormCampo(NUCAMPO,VALOR) {
      Spinner.StarLoad()
      

      let IDIPROC = this.ApontamentoProducao.idiproc
      let IDEFX = this.CICLO.IDEFX
      let AD_CICLO = this.CICLO.AD_CICLO
      
      
      let notification = ''
      
      const usuario = this.$session.get('usuario')
      const URLpost = `/skOperacoesProducao/AtualizarFormulario?CODUSU=${usuario.codusu}&IDIPROC=${IDIPROC}&IDEFX=${IDEFX}&NUCAMPO=${NUCAMPO}&AD_CICLO=${AD_CICLO}&VALOR=${VALOR}`
      // Carrega os Dados no Objeto para enviar no Parametro
      //const ParametroJson = JSON.parse(JSON.stringify(NewCiclo))
      await apiPortalSky.post(URLpost, IDIPROC, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => {
        if (response.status === 200) {
          this.ConsultaCiclosForm()
        }
      })
        .catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })

        if(notification === '') {
         Spinner.EndLoad()
         
         this.CicloEditando = ""
         this.PermiteAddForm = true
        }
    },
    async EditarForm(Ciclo) {

      if(this.CicloEditando == '' || this.CicloEditando == Ciclo.NUCAMPO)
      {
        this.PermiteAddForm = false
        this.CicloEditando = Ciclo.NUCAMPO
      }else{
  
        let notification = { message: "Conclua a Edição anterior", alert_type: 'attention' }
        AlertaMsg.FormataMsg(notification)
      }

    },
    async CancelarEdicao(Ciclo) {
      this.CicloEditando = ""
      this.PermiteAddForm = true
      
      this.ConsultaCiclosForm()

    },    
    async AtualizarForm(Ciclo) {

      let NUCAMPO = Ciclo.NUCAMPO
      let VALOR = Ciclo.VALOR
      this.AtualizarFormCampo(NUCAMPO,VALOR)

    },
    async ConsultaCiclosForm() {

      Spinner.StarLoad()

      let notification = ''
      const usuario = this.$session.get('usuario')
      
      const URLpost = `/skOperacoesProducao/ConsultaApontamentoProducaoFormulario?CODUSU=${usuario.codusu}&IDIPROC=${this.ApontamentoProducao.idiproc}&IDIATV=${this.ApontamentoProducao.idiatv}&AD_CICLO=${this.CICLO.AD_CICLO}&FORMPERDA=true`
      //const ParametroJson = JSON.parse(JSON.stringify(this.ApontamentoProducao))

      await apiPortalSky.post(URLpost, this.ApontamentoProducao.IDIPROC).then(response => {
        if (response.status === 200) {
          this.DescarregaDadosDoObjeto(response.data)          
          
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {
         Spinner.EndLoad()
      }
    },
    async ConsultarOpcoes() {
      let notification = ""
      Spinner.StarLoad()
      const usuario = this.$session.get('usuario')
      
      if(notification === '') {

        let ParametroJson = ""
        let URLpost = `/skOperacoesProducao/ConsultaOpcoesFormulario?CODUSU=${usuario.codusu}&IDIPROC=${this.ApontamentoProducao.idiproc}&IDIATV=${this.ApontamentoProducao.idiatv}&FORMPERDA=true`
        await apiPortalSky.post(URLpost, ParametroJson).then(response => {
          if (response.status === 200) {
            if (response.data.length > 0) {
              this.TipoFormOption = response.data
              this.TipoFormOptionBase = response.data
            } else {
              notification = ConexaoAPI.MsgFiltroSemRetorno()
              AlertaMsg.FormataMsg(notification)
            }
          }
        }).catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
      
      }


      if(notification === '') {
       Spinner.EndLoad()
      }

    },
    DescarregaDadosDoObjeto(data) {
      this.ApontamentoProducao.formulario = data.FORMULARIO
      if(this.ApontamentoProducao.status !=='F' && this.CICLO.SITUACAO != 'C'){
        this.ProcessaTipoForm()
        this.PermiteAddForm = true
      }else
      {
        this.BloquearEdicao = true
        this.PermiteAddForm = false
      }
    },
    ProcessaTipoForm(){

      let NewTipoFormOption = []
      
      this.TipoFormOptionBase.forEach(Opc => {
        let Achou = 'N'        
              this.ApontamentoProducao.formulario.forEach(Form => {
                  if(Opc.NUCAMPO == Form.NUCAMPO){
                    Achou = 'S'
                  }
             })

             if(Achou == 'N'){
             NewTipoFormOption.push(Opc)
             }

       })


      this.TipoFormOption = []
      this.TipoFormOption = NewTipoFormOption

    },
    Retornar() {      
    const $rota = `../../apontamentoProducaoDetalhe/${this.ApontamentoProducao.idiproc}`
    this.$router.push($rota)
    },
  },
  mounted() {
    if (this.$session.exists('ApontamentoProducao')) {
       if (this.$route.params.ad_ciclo != null && this.$route.params.ad_ciclo != "") {
          this.ApontamentoProducao = this.$session.get('ApontamentoProducao')

          let AD_CICLO = this.$route.params.ad_ciclo
          this.CICLO = this.ApontamentoProducao.ciclos.find(item => item.AD_CICLO === AD_CICLO)

          this.ConsultarOpcoes()
          this.ConsultaCiclosForm()
       }else{
        this.$router.push('../ApontamentoProducao')
       }  
       
    }else{
      this.$router.push('../ApontamentoProducao')
    }
    
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
      .InputVlr{
         width: 120px;
        }

</style>